/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import LoadingPage from '@/components/Layouts/LoadingPage';
import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Card,
  Col,
  Divider,
  Layout, message, Row, Typography,
} from 'antd';
import { signOut, useSession } from 'next-auth/react';
import Head from 'next/head';
import Router from 'next/router';
import LayoutBase from '../components/Layouts/LayoutBase';
import { errorToString, graphqlClient } from '../lib/util';
import { graphql } from '../__generated__/gql';

const indexQueryDocument = graphql(`
  query whoamiIndex {
    whoami {
      id
      firstName
      lastName
      emailAddress
      tenantUserRelationships {
        id
        tenantId
        role
        tenant {
          id
          name
          shortId
        }
      }
    }
  }
`);

export default function Home() {
  const session = useSession({ required: true });
  const userQuery = useQuery({
    queryKey: ['indexWhoami', session.data?.user?.email],
    queryFn: async () => graphqlClient.request(indexQueryDocument),
    onError: (e) => message.error(errorToString(e)),
  });
  const user = userQuery.data?.whoami;

  if (user?.tenantUserRelationships.length === 1) {
    Router.replace(`/tenant/${user.tenantUserRelationships[0].tenant.id}`);
    return <LoadingPage />;
  }

  const handleSignOut = async () => {
    await signOut({ callbackUrl: '/sign-in' });
  };

  if (userQuery.isLoading || userQuery.isFetching) return <LoadingPage />;

  if (!user) {
    return (
      <div>
        <Head>
          <meta charSet="UTF-8" />
          <title>Sign In</title>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no" />
        </Head>
        <LayoutBase title="No User">
          <Layout.Content style={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
            <Row style={{ padding: 24 }} justify="center" align="middle">
              <Col flex="368px">
                <Card
                  style={{ marginBottom: 16 }}
                  title="No User Found"
                  // extra={(
                  // )}
                >
                  <Typography.Paragraph>
                    We could not find an associated user and you are not correctly signed in.
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    Please attempt to sign in again.
                  </Typography.Paragraph>
                  <Button
                    onClick={handleSignOut}
                    block
                    size="large"
                    type="primary"
                    // style={{ marginBottom: 24 }}
                  >
                    Sign In
                  </Button>
                </Card>
              </Col>
            </Row>
          </Layout.Content>
        </LayoutBase>
      </div>
    );
  }

  return (
    <div>
      <Head>
        <meta charSet="UTF-8" />
        <title>Sign In</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no" />
      </Head>
      <LayoutBase title="Select an Organization">
        <Layout.Content style={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
          <Row style={{ padding: 24 }} justify="center" align="middle">
            <Col flex="368px">
              <Divider>Select an Organization</Divider>
              { user.tenantUserRelationships.filter((rel) => rel.role !== 'client').map((relationship) => (
                <Card
                  key={relationship.tenant.id}
                  style={{ marginBottom: 16 }}
                  title={relationship.tenant.name}
                  extra={(
                    <Button
                      type="primary"
                      onClick={() => {
                        // setTenantId(relationship.tenant.id);
                        Router.push(`/tenant/${relationship.tenant.id}`);
                      }}
                    >
                      Select
                    </Button>
                  )}
                >
                  You are a <Typography.Text mark strong>{relationship.role}</Typography.Text>
                </Card>
              ))}
              <Button
                onClick={handleSignOut}
                block
                style={{ marginBottom: 24 }}
              >
                Sign Out
              </Button>
            </Col>
          </Row>
        </Layout.Content>
      </LayoutBase>
    </div>
  );
}
